import { Flex, Heading } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const StyledSubheading = styled(Heading)`
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-clip: text;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    -webkit-text-stroke: unset;
    font-size: 18px;
  }

  img {
    width: 36px;
    margin-right: 5px;
    margin-bottom: 5px;
    object-fit: contain;
  }
`

export const StyledHeading = styled(Heading)`
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 24px;
  }
`
export const Wrapper = styled.div`
  border-radius: 32px;
  width: 100%;
  max-height: max-content;
  overflow: visible;
  ${({ theme }) => theme.mediaQueries.sm} {
    overflow: visible;
  }
`

export const Inner = styled(Flex)`
  position: relative;
  padding: 24px 16px;
  flex-direction: row;
  justify-content: space-between;
  max-height: 192px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 32px;
  }
`

export const LeftWrapper = styled(Flex)`
  z-index: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .s-link-join {
    padding-left: 0;
  }
`

export const RightWrapper = styled.div`
  position: absolute;
  right: -17px;
  opacity: 0.9;
  transform: translate(0, -50%);
  top: 50%;

  img {
    height: 100%;
    width: 500px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: 24px;
    bottom: 0;
    transform: unset;
    opacity: 1;
    top: unset;
    height: 211px;
  }
`
