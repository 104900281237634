import { Flex, Text } from '@pancakeswap/uikit'

const StatCardContent: React.FC<{ headingText: string; bodyText: string; highlightColor: string }> = ({
  headingText,
  bodyText,
  highlightColor,
}) => {

  return (
    <Flex
      minHeight={['220px', null, '248px']}
      minWidth="232px"
      width="100%"
      flexDirection="column"
      justifyContent="flex-end"
      gap="16px"
    >
      <Text
        as="p"
        color={highlightColor}
        textTransform="capitalize"
        fontSize={['24px', null, '30px']}
        fontWeight={700}
        lineHeight="1.1"
      >
        {headingText}
      </Text>
      <Text color="textSubtle" fontSize={['18px', null, '20px']} minHeight={60}>
        {bodyText}
      </Text>
    </Flex>
  )
}

export default StatCardContent
