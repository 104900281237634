import { useTranslation } from '@pancakeswap/localization'
import { Button } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'
import Image from 'next/image'
import { memo } from 'react'
import styled from 'styled-components'
import { Rocket } from './images'
import * as S from './Styled'

const RightWrapper = styled.div`
  position: absolute;
  right: 1px;
  bottom: 18px;

  display: none;

  ${({ theme }) => theme.mediaQueries.md} {
    bottom: -45px;
    right: 1px;
    display: block;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    bottom: -45px;
    right: 1px;
  }

  .coin01 {
    width: 50px;
    height: 56px;
    position: absolute;
    top: 10px;
    right: 300px;
    animation: upAndDown 2s linear infinite;
  }
  .coin02 {
    width: 30px;
    height: 36px;
    position: absolute;
    top: 180px;
    right: 270px;
    z-index: 2;
    animation: upAndDown 6s linear infinite;
  }

  @keyframes upAndDown {
    0% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
`

const IFOBanner = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Inner>
        <S.LeftWrapper>
          <S.StyledSubheading>
            {t("Perpetual Futures")}
          </S.StyledSubheading>
          <S.StyledHeading scale="xl" textTransform='uppercase'>{t("Redefining perpetual futures trading")}</S.StyledHeading>
          <NextLinkFromReactRouter to="/swap" className="s-link-join">
            <Button style={{ background: 'transparent', border: '1px solid #FFF' }}>
               {t("Join Now")}
            </Button>
          </NextLinkFromReactRouter>
        </S.LeftWrapper>
        <RightWrapper>
          <Image src={Rocket} alt="" width={298} height={297} placeholder="blur" />
        </RightWrapper>
      </S.Inner>
    </S.Wrapper>
  )
}

export default memo(IFOBanner)
