import { useTranslation } from '@pancakeswap/localization'
import { Button, Flex, Text } from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { NextLinkFromReactRouter } from 'components/NextLink'
import Image from 'next/image'
import styled, { keyframes } from 'styled-components'
import CoinImage from '../../../../public/images/home/Coins.png'

const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const BunnyWrapper = styled.div`
  width: 300px;
  height: 300px;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
    height: auto;
  }
`

const ContentBox = styled(Flex)`
  > h2 {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 12px;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 24px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      margin-bottom: 24px;
      font-size: 36px;
    }
  }

  > p {
    font-size: 16px;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 20px;
      line-height: 30px;
    }
  }
`

const Hero = () => {
  const { t } = useTranslation()
  const account: any = null

  return (
    <Flex
      position="relative"
      flexDirection={['column-reverse', null, null, 'row']}
      alignItems={['flex-end', null, null, 'center']}
      justifyContent={['center', null, null, 'space-between']}
      mt={[account ? '280px' : '50px', null, 0]}
      id="homepage-hero"
    >
      <ContentBox flexDirection="column" width={['100%', null, null, '52%']}>
        <Text as="h2" fontWeight={700} color="secondary">
          {t('Decentralized exchanges offer seamless and transparent trading')}
        </Text>
        <Text as="p" color="secondary" mb={['24px', null, '36px']} fontWeight={500}>
          {t('Trade, earn, and grow your crypto portfolio on our decentralized exchange')}
        </Text>
        <Flex>
          {!account && <ConnectWalletButton mr={['16px', null, '35px']} />}
          <NextLinkFromReactRouter to="/swap">
            <Button variant={!account ? 'secondary' : 'primary'}>{t('Trade Now')}</Button>
          </NextLinkFromReactRouter>
        </Flex>
      </ContentBox>

      <Flex
        height={['100%', null, null, '100%']}
        width={['100%', null, null, '45%']}
        mb={['24px', null, null, '0']}
        position="relative"
        justifyContent="center"
        alignItems="center"
      >
        <BunnyWrapper>
          <Image src={CoinImage} priority placeholder="blur" alt="" />
        </BunnyWrapper>
      </Flex>
    </Flex>
  )
}

export default Hero
