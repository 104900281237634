import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import { PageMeta } from 'components/Layout/Page'
import PageSection from 'components/PageSection'
import WrapBackground from 'components/WrapBackground'
import { Box, Flex, Link, Text } from '@pancakeswap/uikit'

import MultipleBanner from './components/Banners/MultipleBanner'
import { cakeSectionData, earnSectionData, startSectionData, swapSectionData } from './components/SalesSection/data'
import MetricsSection from './components/MetricsSection'
import SalesSection from './components/SalesSection'
import BG1 from './components/images/background.png'
import BG2 from './components/images/background-2.png'
import Hero from './components/Hero'
import TradeSection from './components/TradeSection'
import EarnSection from './components/EarnSection'
import ColoredWordHeading from './components/ColoredWordHeading'
import LockupsSection from './components/LockupsSection'


const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '1060px' }
const HomeSectionContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  & > div {
    width: 100%;
    max-width: 1060px;
    margin: 0;
  }
`
const Home: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div>
      <PageMeta />

      <WrapBackground src={BG2.src}>
        <WrapBackground src={BG1.src}>
          <div
            style={{
              width: '100%',
              height: '1px',
              marginTop: '-1px',
            }}
          />
          <PageSection
            innerProps={{ style: { margin: '0', width: '100%' } }}
            containerProps={{
              id: 'home-1',
            }}
            index={2}
            hasCurvedDivider={false}
          >
            <MultipleBanner />
            <Hero />
          </PageSection>
     
          <PageSection innerProps={{ style: HomeSectionContainerStyles }} index={2} hasCurvedDivider={false}>
            <MetricsSection />
          </PageSection>
        </WrapBackground>
        <PageSection
          innerProps={{ style: { ...HomeSectionContainerStyles, paddingTop: '0', paddingBottom: '100px' } }}
          containerProps={{
            id: 'home-4',
          }}
          index={2}
          hasCurvedDivider={false}
          padding="0"
        >
          <TradeSection {...swapSectionData(t)} />
        </PageSection>
      </WrapBackground>
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%', paddingTop: '0rem' } }}
        index={2}
        hasCurvedDivider={false}
      >
        <img
          src="/images/home/top-farm-bg.png"
          alt=""
          style={{
            left: 0,
            bottom: 0,
            width: '100%',
            height: 'calc(100% + 4.5rem)',
            position: 'absolute',
            objectFit: 'fill',
          }}
        />
        <img
          src="/images/home/top-farm-bg-icon-1.png"
          alt=""
          style={{
            right: 0,
            top: '2rem',
            position: 'absolute',
          }}
        />
        <img
          src="/images/home/top-farm-bg-icon-2.png"
          alt=""
          style={{
            left: 0,
            bottom: '2rem',
            position: 'absolute',
          }}
        />
        <HomeSectionContainer style={{ marginBottom: '2rem' }}>
          <div>
            <EarnSection {...earnSectionData(t)} />
          </div>
        </HomeSectionContainer>
      </PageSection>
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        index={2}
        hasCurvedDivider={false}
      >
        <img
          src="/images/home/cake-section-icon-1.png"
          alt=""
          style={{
            left: 0,
            bottom: '0rem',
            position: 'absolute',
          }}
        />
        <img
          src="/images/home/cake-section-icon-2.png"
          alt=""
          style={{
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            position: 'absolute',
          }}
        />
        <Flex position="relative">
          <ColoredWordHeading
            text={t('Lockups')}
            fontWeight="700 !important"
            mb="12px"
            fontSize={['22px', null, null, '24px', '26px']}
          />
        </Flex>
        <LockupsSection {...cakeSectionData(t)} />
      </PageSection>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        index={2}
        hasCurvedDivider={false}
        mb={50}
      >
        <SalesSection {...cakeSectionData(t)} />
      </PageSection>

      <PageSection
        innerProps={{ style: { ...HomeSectionContainerStyles, paddingBottom: '60px' } }}
        background="rgba(113, 64, 211, 0.80);"
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection
          {...startSectionData(t)}
          bodyText={
            <Box>
              <Text fontSize={['16px', null, '16px', '20px']} fontWeight={500}>
                {t('Connect your crypto wallet to start using the app in seconds.')}
              </Text>
              <Text fontSize={['16px', null, '16px', '20px']} fontWeight={700}>
                {t('No registration needed.')}
              </Text>
              <Link href="/" fontSize={['16px', null, '16px', '20px']} fontWeight={700} mt="10px">
                {t('Learn how to start')}
              </Link>
            </Box>
          }
        />
      </PageSection>
    </div>
  )
}

export default Home
